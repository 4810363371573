import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import { graphql } from 'gatsby'

import styles from "./bookings.module.scss";

export default ({ data }) => {
  return (
    <Layout>

      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Bookings | {data.site.siteMetadata.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="View the Fume Restobar Menu. Fume RestoBar is now open in Holmedale in Brantford!" />
        <meta property="og:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta property="og:description" content="Book a table and view the booking guidelines for Fume Restobar." />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
      </Helmet>

        {data.allWordpressPage.edges.map(({ node }) => (
          <div className={styles.about}>
            <div className={styles.content} dangerouslySetInnerHTML={{__html: node.content }} />
          </div>
        ))}

    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        siteUrl
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "bookings" }}
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`
